import React from 'react';
import '../../styles/HomeMain.css';

const HomeMain = () => {
  return (
    <div className="banner">
      <div className="banner-content">
        <h1>Welcome to Dhankosh</h1>
      </div>
    </div>
  );
};

export default HomeMain;
