import React from 'react'
import "../../styles/Aboutus.css"

const AboutUs = () => {
  return (
    <>
          <div className="aboutcard" id="aboutus">
        <div className="aboutbox">
          <div className="main">
            <div className="about">
            <h2>About Us </h2>
              <h1>Welcome to Dhankosh</h1>
              <p>At Dhankosh, we are driven by a simple yet powerful mission: to transform access to vehicle loans in India’s tier-2 and smaller cities. We believe that owning a vehicle is more than just a convenience—it’s a key to unlocking financial independence, economic growth, and a better quality of life. Our innovative platform leverages cutting-edge technology and strong local partnerships to bridge the significant gaps in the vehicle loan market, empowering millions of individuals in underserved areas.</p>
              <h1>Our Vision</h1>
              <p>We envision a future where every aspiring vehicle owner in India, regardless of location, has access to affordable and transparent financing options. We are committed to making this vision a reality by creating a robust ecosystem that benefits all stakeholders—borrowers, agents, and financial institutions alike.</p>
              <h1>What Sets Us Apart</h1>
              <p>Dhankosh stands out in the market through our unique approach to vehicle financing. By focusing on the core needs of agents, who are central to the vehicle loan process, we have developed tools and systems that enhance their efficiency and loyalty. Our platform simplifies the loan process, reduces administrative burdens, and ensures compliance with regulatory standards. This, in turn, leads to better outcomes for borrowers and more secure transactions for banks.</p>
              <h1>Our Impact</h1>
              <p>Our journey is about more than just providing loans. We aim to foster economic growth in India's rapidly developing regions by enabling individuals to achieve their dreams of vehicle ownership. We are committed to making a positive impact on the lives of our customers, helping them unlock new opportunities for mobility, employment, and financial stability.</p>
              <h1>Join Us on This Journey</h1>
              <p>As we continue to grow and evolve, we invite you to be part of our journey. Whether you’re a potential customer, partner, or investor, there’s a place for you in the Dhankosh family. Together, we can make vehicle ownership accessible to all and drive positive change in the communities we serve.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs