import React from 'react';
import "../../styles/WhyDhankosh.css"; 
import topfinancing from "../../assets/images/topfinancing.gif"
import LoanProcess from "../../assets/images/Loan-Process.gif"
import Fastpayout from "../../assets/images/truck.gif"
import speedlyloan from "../../assets/images/speedlyloan.gif"
import Data from "../../assets/images/encrypted.gif"

const WhyDhankosh = () => {
  return (
    <div className="container">
      <div className="whybox">
        <div className="commonflex">
          <h1>Dhankosh Promise</h1>
        </div>
        <div className="cardflex">
          <div className="commoncard">
            <img
              src={topfinancing}
              alt="Top Financing Options"
              width={80}
              height={80}
            />
            <h3>1. Top Financing Options</h3>
            <p>Access to the most competitive loan offers.</p>
          </div>

          <div className="commoncard">
            <img
              src={Fastpayout}
              alt="Fastpayout"
              width={80}
              height={80}
            />
            <h3>2. Unbeatable and Fast Payouts </h3>
            <p>Best in class payouts.</p>
          </div>

          <div className="commoncard">
            <img
              src={speedlyloan}
              alt="speedlyloan"
              width={80}
              height={80}
            />
            <h3>3. Speedy Loans</h3>
            <p>Fast & Efficient Loan Processing.</p>
          </div>
        </div>
        <div className="cardflex">
        <div className="commoncard">
            <img
              src={LoanProcess}
              alt="Loan-Process"
              width={80}
              height={80}
            />
            <h3>4. Clear Loan Process </h3>
            <p>Complete transparency throughout the loan process.</p>
          </div>

          <div className="commoncard">
            <img
              src={Data }
              alt="data-confidentiality"
              width={80}
              height={80}
            />
            <h3>5. Data Confidentiality </h3>
            <p>Your Data your Control</p>
          </div>
          </div>
      </div>
    </div>
  );
};

export default WhyDhankosh;
