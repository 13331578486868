import React from 'react';
import '../../styles/ContactCard.css';

const ContactCard = () => {
  return (
    <div className="contact-card">
      <h1>Meet us</h1>
      <div className="contact-info">
        {/* <p><i className="fas fa-phone"></i> <a href="tel:+40728303133">+9876543210</a></p> */}
        <p><i className="fas fa-envelope"></i> <a href="mailto:care@dhankosh.com">care@dhankosh.com</a></p>
        <p style={{color:"white"}}><i className="fas fa-map-marker-alt"></i> Office address: Oahfeo, 1094P, Sector 46, Gurgaon, Haryana - 122001</p>
      </div>
    </div>
  );
}

export default ContactCard;
