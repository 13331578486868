import React from "react";
import "../../styles/OurBankPartners.css";
import Hdblogo from "../../assets/images/hdblogo.svg";
import Herologo from "../../assets/images/herofincorplogo.png";
import Vastulogo from "../../assets/images/vastulogo.png";
import iffcologo from "../../assets/images/iffcologo.svg";
import idfclogo from "../../assets/images/IDFCbanklogo.png";
import cholalogo from "../../assets/images/cholalogo.png";
import hdfclogo from "../../assets/images/hdfclogo.png";
import poonawalalogo from "../../assets/images/poonawalalogo.png";
import piramallogo from "../../assets/images/piramalfinancelogo.png";
import mahinndralogo from "../../assets/images/mahindra-finance-logo.png";
import AUlogo from "../../assets/images/AUbank.webp";

const OurBankPartners = () => {
  const logos = [
    Hdblogo,
    Herologo,
    Vastulogo,
    iffcologo,
    idfclogo,
    cholalogo,
    hdfclogo,
    poonawalalogo,
    piramallogo,
    mahinndralogo,
    AUlogo
  ];

  return (
    <div className="container">
      <div className="ourbankbox">
        <h1>Our Bank Partners</h1>
        <div className="bankdetails">
          {logos.map((logo, index) => (
            <img key={index} src={logo} alt={`logo-${index}`} width={200} height={200} />
          ))}
          {logos.map((logo, index) => (
            <img key={`dup-${index}`} src={logo} alt={`logo-dup-${index}`} width={200} height={200} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurBankPartners;
