import React, { useEffect } from "react";
import '../../styles/Footer.css'; 
import Footerlogo from '../../assets/images/footerlogo.png';
import { Link } from "react-router-dom";
import ContactCard from "../contactcard/ContactCard";

const Footer = () => {
    useEffect(() => {
        const handleNavigation = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };

        const links = document.querySelectorAll('.footer-links a');
        links.forEach(link => link.addEventListener('click', handleNavigation));

        return () => {
            links.forEach(link => link.removeEventListener('click', handleNavigation));
        };
    }, []);
    return (
        <div className="footercard">
            <div className="container">
                <div className="box">
                    
                    <div className="footer-logo">
                        <a href="/">
                            <img
                                src={Footerlogo}
                                alt="dhankosh-logo"
                                width={182}
                                height={56}
                            />
                        </a>
                        <ContactCard />
                    </div>

                    
                    <div className="footer-links">
                        <h3>NAVIGATION</h3>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about-us">About Us</Link></li>
                            <li><Link to="/contact-us">Contact Us</Link></li>
                            <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                        </ul>
                    </div>

                
                    <div className="footer-right">
                        <div className="footer-partner">
                            <Link to="/partner-with-us"><h4>Partner with Us</h4></Link>
                        </div>
                        <div className="footer-map">
                            <iframe
                                title="Google Map"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.4384385152407!2d77.0513777!3d28.436196900000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19a3608dfef5%3A0xfc3d1612c70efe3b!2sOahfeo%20Dreams!5e0!3m2!1sen!2sin!4v1725481778852!5m2!1sen!2sin" 
                                width="100%"
                                height="200"
                                frameBorder="0"
                                style={{ border: 0, borderRadius: '8px' }}
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <hr />

            <div className="copyright">
                <div className="copyrightpadding">
                    Copyright © 2024 Rapidlend Technologies Pvt Ltd. All rights reserved
                </div>
            </div>
        </div>
    );
};

export default Footer;
