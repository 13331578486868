import React from 'react';
import "../../styles/Contactus.css"
import phoneicon from "../../assets/images/viber.png"
import headseticon from "../../assets/images/chat.png"
function Contactus() {
  return (
    <div className="contact-section">
      <h2 className='setmargin'>Get in touch</h2>
      <p className='setwidthp'>Want to get in touch? We'd love to hear from you. Here's how you can reach us ...</p>
      <div className="contact-container">
        <div className="contact-box sales">
          <img className='phone-icon' src={phoneicon} alt="phone-icon"  width={40}/>
          <h3>Talk to Sales</h3>
          <p>Interested in HubSpot's software? Just pick up the phone to chat with a member of our sales team.</p>
          {/* <p><a href="tel:+35315124400">+9876543210</a></p> */}
        </div>
        <div className="contact-box support">
          <img src={headseticon} alt="headset-icon" width={40} />
          <h3>Contact Customer Support</h3>
          <p>Sometimes you need a little help from your friends. Or a HubSpot support rep. Don't worry... we're here for you.</p>
        </div>
      </div>
    </div>
  );
}

export default Contactus;
