import React from 'react'
import WhyDhankosh from '../homepage/WhyDhankosh'
import OurBankPartners from '../homepage/OurBankPartners'
import HomeMain from '../homepage/HomeMain'
import PartnerWithUs from '../homepage/PartnerWithUs'

const Homepage = () => {
    return (
        <>
            <HomeMain />
            <PartnerWithUs />
            <WhyDhankosh />
            <OurBankPartners />
        </>
    )
}

export default Homepage