import React, { useState, useEffect } from 'react';
import { Country, State, City } from 'country-state-city';
import '../../styles/PartnerWithUs.css';

const PartnerWithUs = () => {
  const [formData, setFormData] = useState({
    typeofpartner: '',
    name: '',
    email: '',
    mobile: '',
    country: 'IN',
    state: '',
    city: '',
    // message: '',
  });

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [formIsValid, setFormIsValid] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
  
    const statesByCountry = State.getStatesOfCountry('IN');
    setStates(statesByCountry);
  }, []);

  useEffect(() => {
    if (formData.state) {
      const citiesByState = City.getCitiesOfState('IN', formData.state);
      setCities(citiesByState);
      setFormData((prevData) => ({ ...prevData, city: '' }));
    }
  }, [formData.state]);

  useEffect(() => {
    const errors = {};

    if (formData.name && (formData.name.length < 2 || formData.name.length > 50)) {
      errors.name = 'Name must be between 2 and 50 characters.';
    }
    if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = 'Invalid email format.';
    }
    if (formData.mobile && !/^[0-9]{10,12}$/.test(formData.mobile)) {
      errors.mobile = 'Please enter a valid 10-digit mobile number.';
    }
    // if (formData.message && (formData.message.length < 5 || formData.message.length > 200)) {
    //   errors.message = 'Message must be between 5 and 200 characters.';
    // }

    setValidationErrors(errors);
    setFormIsValid(Object.keys(errors).length === 0);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formIsValid) {
      alert('Please correct the errors in the form.');
      return;
    }

    try {
      const response = await fetch('http://localhost:5000/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Form submitted successfully!');
        setFormData({
          typeofpartner: '',
          name: '',
          email: '',
          mobile: '',
          country: 'IN',
          state: '',
          city: '',
          // message: '',
        });
      } else {
        alert('Failed to submit the form.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting the form.');
    }
  };

  return (
    <div className="form-container">
      <h1>Partner With Us</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Type of Partner
          <select
            name="typeofpartner"
            value={formData.typeofpartner}
            onChange={handleChange}
          >
            <option value="" disabled>Select the type of Partner</option>
            <option value="Dealer">Dealer</option>
            <option value="Agent">Agent</option>
            <option value="Broker">Broker</option>
            <option value="Others">Others</option>
          </select>
          {validationErrors.typeofpartner && <p className="error">{validationErrors.typeofpartner}</p>}
        </label>
        <label>
          Full Name
          <input
            type="text"
            name="name"
            placeholder="Enter Your Full Name"
            value={formData.name}
            onChange={handleChange}
          />
          {validationErrors.name && <p className="error">{validationErrors.name}</p>}
        </label>
        <label>
          Email
          <input
            type="email"
            name="email"
            placeholder="Enter Your Email Id"
            value={formData.email}
            onChange={handleChange}
          />
          {validationErrors.email && <p className="error">{validationErrors.email}</p>}
        </label>
        <label>
          Mobile
          <input
            type="text"
            name="mobile"
            placeholder="Enter Your Mobile Number"
            value={formData.mobile}
            onChange={(e) => {
              const filteredValue = e.target.value.replace(/[^\d]/g, '');
              if (filteredValue.length <= 12) {
                setFormData((prevData) => ({ ...prevData, mobile: filteredValue }));
              }
            }}
            required
            pattern="\d{10,12}"
            title="Please enter a valid 10 to 12 digit mobile number"
          />
          {validationErrors.mobile && <p className="error">{validationErrors.mobile}</p>}
        </label>
        <label>
          State
          <select
            name="state"
            value={formData.state}
            onChange={handleChange}
            required
          >
            <option value="" disabled>Select Your State</option>
            {states.map((state) => (
              <option key={state.isoCode} value={state.isoCode}>{state.name}</option>
            ))}
          </select>
          {validationErrors.state && <p className="error">{validationErrors.state}</p>}
        </label>
        <label>
          City
          <select
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          >
            <option value="" disabled>Select Your City</option>
            {cities.map((city) => (
              <option key={city.id} value={city.name}>{city.name}</option>
            ))}
          </select>
          {validationErrors.city && <p className="error">{validationErrors.city}</p>}
        </label>
        {/* <label>
          Message
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
          />
          {validationErrors.message && <p className="error">{validationErrors.message}</p>}
        </label> */}
        <div className="btn-container">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default PartnerWithUs;
